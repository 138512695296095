import React from "react";
import { efbet } from "../assetsExport";

const EfbetAd = ()=> {
    return(
        <section>
            <div className="efbet">
                <a href="https://cdn1.efbet.com/efbet/landingpage/casino/index.html?utm_source=gerena&utm_medium=cpm&utm_campaign=legalbranding&utm_content=980x120" target="_blank">
                    <img src={efbet} alt="" />
                </a>
            </div> 
        </section> 
    )
};

export default EfbetAd;